@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body{
  /* font-family:  "Open Sans", sans-serif; */
  font-family: 'Montserrat';
}

.App{
background-color: #e7eaf9;
}

.entry-page{
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
}

.header{
  background-color: #1131c0;
  border-radius: 0 0 12px 12px;
  color: #faf9f9;
  padding: 16px 0;

  transition: height .8s, padding-bottom .8s, transform .8s;

  display: flex;
  justify-content: center;
}

.warning-message{
  margin-left: 5vw;
}

.main-title{
    font-weight: 700;
    font-size: 58px;
    line-height: 34px;
}

.wr-main-title{
  display: flex;
}

.wr-main-title img {
  height: 60px;
}

.wr-mini-dialog-window{
  font-size: 0.8rem;
  padding: 10px;
  background-color: #D9D9D9;
  border-radius: 16px;
  color: #686868;
  width: 300px;
  position: relative;
 
}

.small-icon-to-dialog-window{
  position: absolute;
  left: 15px;
  top: -11.8px;
}

.wr__container-app-download{
  display: flex;
  justify-content: center;
  padding: 50px 0px 50px 0px;
}

.container-app-download{
  border-radius: 16px;
  background-color: #fcfdff;
  padding: 48px;
  width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flame-app{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 15px;
  font-size: 1.2rem;
  font-weight: 600;
}

.flame-download{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.flame-download img{  
  height: 30px;
}
.flame-download a{  
  font-family: 'Montserrat';
}

@media (max-width: 480px) {
  .container-app-download{
    border-radius: 16px;
    background-color: #fcfdff;
    padding: 20px;
    margin: 0px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}